import './portrait.scss'
import { downloadUrlEnum } from '../../enums'
let type = ''
let downloadUrl = downloadUrlEnum.Universal
if (window.location.search !== '' && window.location.search.includes('s=qiuqiu')) {
  type = 'qiuqiu'
  downloadUrl = downloadUrlEnum.qiuqiu
} else if (window.location.search !== '' && window.location.search.includes('s=taikongsha')) {
  type = 'taikongsha'
  downloadUrl = downloadUrlEnum.taikongsha
} else {
  type = 'diantang'
}

/**
 * @description 是否是 andorid
 * @returns
 */
const isAndroid = () => {
  return navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1
}
const goDwonLoad = (val) =>{
  window._gtBuryPoint?.send({
    'event_name': '下载按钮点击'
  })
  if(isAndroid()) {
    window.location.href = downloadUrl
  }else {
    window.location.href = 'https://apps.apple.com/cn/app/%E7%B3%96%E8%B1%86-%E7%90%83%E7%90%83%E5%A4%A7%E4%BD%9C%E6%88%98%E5%AE%98%E6%96%B9%E7%A4%BE%E5%8C%BA/id6450919312'
  }
}

// 电糖社区调整进电糖文章详情
// if (window.location.search !== '' && window.location.search.includes('toLink=')) {
//   const getSearch = window.location.search.split('toLink=')
//   window.location.href = getSearch[1]
//   setTimeout(() => {
//     window.location.href = 'https://apps.apple.com/cn/app/%E7%B3%96%E8%B1%86-%E7%90%83%E7%90%83%E5%A4%A7%E4%BD%9C%E6%88%98%E5%AE%98%E6%96%B9%E7%A4%BE%E5%8C%BA/id6450919312'
//   }, 2000)
// }


function PortraitScreen(){
  return <div className="screen screen--portrait">
    <header>
      <div className="logo"></div>
    </header>
    <section className={`phone-bg ${type}`}>
    </section>
    <div className="download-btn" onClick={goDwonLoad}></div>
    <footer>
        <div className="brand">
          ©2023 上海巨人网络科技有限公司 版权所有
        </div>
        <div className="links">
        <a href="https://beian.miit.gov.cn" className="link">沪B2-20050107-60</a> |
        <div className="link">沪网文(2020)4148-283</div> |
        <a href="/privacy_policy.html" className="link">用户协议</a> |
        <a href="/user_agreement.html" className="link">隐私政策</a>
        </div>
    </footer>
  </div>
}

export default PortraitScreen;