import React from 'react';
import PcScreen from './portrait'
class App extends React.Component{
  render(){
    return <div className="App">
      {
       <PcScreen></PcScreen>
      }
    </div>
  }
}

export default App;
