import React from 'react';
import { DeviceTypeEnum } from './enums'
import { PcScreen, MobileScreen } from './views'
import { getBuryPointChannel } from './utils'
const docEl = document.documentElement
class App extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      screenMode: false
    }
  }

  componentDidMount(){
    window._gtBuryPoint?.setParam('activity_name', '糖豆官网')
    window._gtBuryPoint?.setParam('page_name', '首页')
    window._gtBuryPoint?.setParam('channel', getBuryPointChannel())
    window._gtBuryPoint?.send({
      'event_name': '页面访问'
    })
    this.setUpScreenMode()
    window.addEventListener('resize', () => {
      this.setUpScreenMode()
    })
  }

  setUpScreenMode(){
    let { width, height } = docEl.getBoundingClientRect()
    let screenMode = DeviceTypeEnum.PC
    if(width > 800 && height > 400){
      screenMode = DeviceTypeEnum.PC
      document.documentElement.style.fontSize = '';
    }else{
      screenMode = DeviceTypeEnum.MOBILE;
      //document.documentElement.style.color = 'green';
    }
    this.setState({
      screenMode
    })
    window.SCREEN_MODE = screenMode
    return screenMode
  }

  render(){
    const {screenMode} = this.state;
    return <>
            {
              (screenMode !== false) ? 
                <>
                  {
                    screenMode === DeviceTypeEnum.PC ?
                    <PcScreen></PcScreen> :
                    <MobileScreen></MobileScreen>
                  }
                </>
                : 
                <>1</>
            }
          </>
         
  }
}

export default App;
