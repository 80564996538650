import { downloadUrlEnum } from '../../enums'
import './pc.scss'
// 超大屏幕处理2560
let bigWind = false
if ( window.innerWidth > 2060 || window.screen.width > 2060 || window.outerWidth > 2060) {
  bigWind = true
}

let type = ''
let downloadUrl = downloadUrlEnum.Universal
if (window.location.search !== '' && window.location.search.includes('s=qiuqiu')) {
  type = 'qiuqiu'
  downloadUrl = downloadUrlEnum.qiuqiu
} else if (window.location.search !== '' && window.location.search.includes('s=taikongsha')) {
  type = 'taikongsha'
  downloadUrl = downloadUrlEnum.taikongsha
} else {
  type = 'diantang'
}

const goDwonLoad = (val) => {
  window._gtBuryPoint?.send({
    'event_name': `${val}下载按钮点击`
  })
  if (val === '安卓') {
    window.location.href = downloadUrl
  } else {
    window.location.href = 'https://apps.apple.com/cn/app/%E7%B3%96%E8%B1%86-%E7%90%83%E7%90%83%E5%A4%A7%E4%BD%9C%E6%88%98%E5%AE%98%E6%96%B9%E7%A4%BE%E5%8C%BA/id6450919312'
  }
}


function PcScreen (){
  
  return <div className="screen screen--pc">
      <header>
        <div className="logo"></div>
        {/* <div className="recharge">{type}</div> */}
      </header> 
      <section className={`content ${bigWind===true?"bigWind":''}`}>
        <div className={`conent__text ${bigWind===true?"bigWind":''}`}>
          <div className={`content__text-title ${type}`}></div>
          <div className="download-btns">
              <div onClick={() => goDwonLoad('IOS')} className="btn btn--app-store">
                {/* <div className='btn-beat-icon-pc'>
                    beta
                </div> */}
              </div>
              <div onClick={() => goDwonLoad('安卓')} className="btn btn--google-play">
                {/* <div className='btn-beat-icon-pc'>
                    beta
                </div> */}
              </div>
          </div>
        </div>
        <div className={`conent__image ${type} ${bigWind===true?"bigWind":''}`}></div>
      </section>
      <footer>
        <div className="brand">©2023 上海巨人网络科技有限公司 版权所有</div>
        <div className="copyright">
          <div className="links">
            <a href="https://beian.miit.gov.cn" className="link">沪B2-20050107-60</a> |
            <div className="link">沪网文(2020)4148-283</div> |
            <a href="/privacy_policy.html" className="link">用户协议</a> |
            <a href="/user_agreement.html" className="link">隐私政策</a>
            </div>
        </div>
      </footer>
    </div>
}

export default PcScreen