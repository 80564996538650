import Enum from './enum'
function getQueryString(key: string, href = '') {
  let search = href || window.location.search
  const { location: { hash } } = window
  if (!search && hash) {
    search = hash.substring(1)
  }
  const reg = new RegExp(`(^|\\?|&)${key}=([^&]*)(&|$)`)
  const res = search.match(reg)
  return res ? res[2] : ''
}

function getBuryPointChannel () {
  const result = getQueryString('s')
  return result
}

export {
  Enum,
  getBuryPointChannel
}